import React, { Component } from 'react';
import logo from './logo.svg';
import './index.scss';

class Profile extends Component {
  render() {
    return (
      <div className="Profile">
        <header className="Profile-header">
          <h1>Profile</h1>
        </header>
      </div>
    );
  }
}

export default Profile;
